<template>
  <div>
    <vs-row>
      <vs-col vs-w="6" class="pr-5">
        <vx-card>
          <h3 class="font-bold mb-6">Page header</h3>
          <div>
            <vs-input
              class="w-full"
              v-validate="'required|max:100'"
              size="large"
              maxLength="100"
              label-placeholder="Business name*"
              v-model="companyName"
              name="companyName"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('companyName')"
              >{{ errors.first("companyName") }}</span
            >
          </div>
          <div>
            <vs-input
              class="w-full"
              v-validate="'required'"
              size="large"
              label-placeholder="Abn*"
              v-model="abn"
              name="abn"
            />
            <span class="text-danger text-sm" v-show="errors.has('abn')">{{
              errors.first("abn")
            }}</span>
          </div>
          <div>
            <vs-input
              class="w-full"
              size="large"
              label-placeholder="Address"
              v-model="address"
              name="address"
            />
          </div>
          <div class="relative ph-phone mt-3">
            <vs-input
              class="w-full"
              v-validate="'required'"
              size="large"
              maxLength="15"
              label-placeholder="Phone*"
              v-model="phoneNumber"
              name="phoneNumber"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('phoneNumber')"
              >{{ errors.first("phoneNumber") }}</span
            >
          </div>
          <div>
            <vs-input
              class="w-full"
              v-validate="'required'"
              size="large"
              label-placeholder="Company email*"
              v-model="companyEmail"
              name="companyEmail"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('companyEmail')"
              >{{ errors.first("companyEmail") }}</span
            >
          </div>
          <div>
            <vs-input
              class="w-full"
              size="large"
              label-placeholder="Website"
              v-validate="'url'"
              v-model="link"
              name="link"
            />
            <span class="text-danger text-sm" v-show="errors.has('link')">{{
              errors.first("link")
            }}</span>
          </div>

          <h3 class="font-bold mt-10 mb-6">Customisable text block</h3>

          <div>
            <vs-input
              class="w-full"
              v-validate="'required|max:100'"
              size="large"
              maxLength="100"
              label-placeholder="Header*"
              v-model="companyHeader"
              name="companyHeader"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('companyHeader')"
              >{{ errors.first("companyHeader") }}</span
            >
          </div>
          <div
            :class="
              `relative fm-textarea mt-5 ${
                companyDescription ? 'hasValue' : ''
              }`
            "
            id="custom-focus"
          >
            <vs-textarea
              class="custom-textarea"
              v-model="companyDescription"
              id="custom-textarea"
              maxLength="1000"
              v-validate="'max:1000'"
            />
            <span
              class="input-span-placeholder vs-input--placeholder large large vs-placeholder-label"
            >
              Body
            </span>
            <span
              class="text-danger text-sm"
              v-show="errors.has('companyDescription')"
              >{{ errors.first("companyDescription") }}</span
            >
          </div>

          <h3 class="font-bold mt-10 mb-6">Company logo</h3>
          <p>
            Logo file must be "PNG" format and no more than 110 pixels high
            (otherwise the image may be altered by the browser when displayed). <br />
            <br /><br />
            The logo image currently in use is displayed below.
          </p>
          <div class="w-full ">
            <div class="  vx-col w-full vs-con-loading__container">
              <template v-if="image">
                <!-- Image Container -->
                <div class="flex">
                  <div class="img-container w-64">
                    <img :src="image" alt="img" class="responsive" />
                  </div>
                  <feather-icon
                    icon="XCircleIcon"
                    class="ml-1"
                    @click="image = null"
                  />
                </div>

                <!-- Image upload Buttons -->
                <div class="modify-img flex my-8">
                  <input
                    type="file"
                    class="hidden"
                    ref="updateImgInput"
                    @change="updateCurrImg"
                    v-validate="'size:5000'"
                    name="companyLogo"
                    accept="image/*"
                  />
                  <vs-button
                    class="mr-4 ml-auto"
                    @click="$refs.updateImgInput.click()"
                    >Logo new logo
                  </vs-button>
                </div>
              </template>

              <div class="flex upload-img my-5" v-if="!image">
                <input
                  type="file"
                  name="companyLogo"
                  v-validate="'required|size:5000'"
                  class="hidden"
                  ref="uploadImgInput"
                  @change="updateCurrImg"
                  accept="image/*"
                />
                <vs-button class="ml-auto" @click="$refs.uploadImgInput.click()"
                  >Load new logo</vs-button
                >
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('companyLogo')"
                >{{ errors.first("companyLogo") }}</span
              >
            </div>
          </div>
        </vx-card>
      </vs-col>

      <vs-col vs-w="6">
        <vx-card>
          <h3 class="font-bold  mb-6">Page detail</h3>
          <div>
            <vs-input
              class="w-full"
              v-validate="'required'"
              size="large"
              label-placeholder="Title*"
              v-model="title"
              name="title"
            />
            <span class="text-danger text-sm" v-show="errors.has('title')">{{
              errors.first("title")
            }}</span>
          </div>
          <div class="mt-2">
            <label width="100%">Page request types</label>
            <ul class="centerx">
              <li>
                <vs-checkbox
                  name="requestTypes"
                  v-model="requestTypes"
                  v-validate="'required'"
                  vs-value="one-off"
                  >One-off</vs-checkbox
                >
              </li>
              <li>
                <vs-checkbox
                  name="requestTypes"
                  v-model="requestTypes"
                  v-validate="'required'"
                  vs-value="recurring"
                  >Recurring</vs-checkbox
                >
              </li>
              <li>
                <vs-checkbox
                  name="requestTypes"
                  v-model="requestTypes"
                  v-validate="'required'"
                  vs-value="pay-later"
                  >Pay later</vs-checkbox
                >
              </li>
            </ul>
            <span
              class="text-danger text-sm"
              v-show="errors.has('requestTypes')"
              >{{ errors.first("requestTypes") }}</span
            >
          </div>
        </vx-card>
        <vx-card class="mt-5">
          <h3 class="font-bold  mb-6">User access</h3>

          <div class="lg:w-3/4">
            <vs-row>
              <vs-col vs-w="6" class="pr-5 text-center user-text font-bold"
                >Allowed to use?</vs-col
              >
              <vs-col vs-w="6" class="pr-5 user-text font-bold">User</vs-col>
            </vs-row>
            <hr class="mt-2 mb-2" />
            <vs-row v-for="(staff, key) in staffs" :key="key">
              <vs-col vs-w="6" class="check-wrap">
                <vs-checkbox
                  v-model="staffId"
                  :vs-value="staff._id"
                ></vs-checkbox>
              </vs-col>
              <vs-col vs-w="6">
                {{ staff.fullName }}
              </vs-col>
            </vs-row>
          </div>
        </vx-card>
        <vx-card class="mt-5">
          <h3>Bank detail</h3>
          <div>
            <vs-input
              class="w-full"
              v-validate="'required'"
              size="large"
              label-placeholder="Account name*"
              v-model="bank.accountName"
              name="accountName"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('accountName')"
              >{{ errors.first("accountName") }}</span
            >
          </div>
          <div>
            <vs-input
              class="w-full"
              v-validate="'required'"
              size="large"
              label-placeholder="Account number*"
              v-model="bank.accountNumber"
              name="accountNumber"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('accountNumber')"
              >{{ errors.first("accountNumber") }}</span
            >
          </div>
          <div>
            <vs-input
              class="w-full"
              v-validate="'required'"
              size="large"
              label-placeholder="BSB*"
              v-model="bank.bsb"
              name="bsb"
            />
            <span class="text-danger text-sm" v-show="errors.has('bsb')">{{
              errors.first("bsb")
            }}</span>
          </div>
        </vx-card>
        <div class="flex flex-wrap mt-5 p-8">
          <vs-button class="mr-auto" @click="savePage" size="large"
            >Save changes</vs-button
          >
          <vs-button
            class="ml-auto"
            color="danger"
            @click="$router.go(-1)"
            size="large"
            >Cancel</vs-button
          >
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";

const dictionary = {
  en: {
    attributes: {
      companyName: "business name",
      companyHeader: "header",
      companyDescription: "body",
      accountName: "account name",
      accountNumber: "account number",
      requestTypes: "page request types",
      companyLogo: "logo",
      phoneNumber: "phone ",
      companyEmail: "company email",
      url: "website"
    }
  }
};
Validator.localize(dictionary);
export default {
  components: { TheMask },
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      title: "",
      companyName: "",
      companyHeader: "",
      abn: "",
      address: "",
      phoneNumber: "",
      link: "",
      companyEmail: "",
      companyDescription: "",
      companyLogo: "",
      image: "",
      requestTypes: [],
      staffs: [],
      staffId: [],
      bank: {
        accountName: "",
        accountNumber: "",
        bsb: ""
      }
    };
  },
  methods: {
    ...mapActions("page", ["fetchPagesById", "updatePageById", "createPage"]),
    ...mapActions("partner", ["fetchStaffByPartnerId"]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.companyLogo = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async getPageById(id) {
      await this.fetchPagesById(id)
        .then(result => {
          this.title = result.data.data.title;
          this.companyName = result.data.data.companyName;
          this.companyHeader = result.data.data.companyHeader;
          this.abn = result.data.data.abn;
          this.address = result.data.data.address;
          if (result.data.data.companyEmail)
            this.companyEmail = result.data.data.companyEmail;
          // if (result.data.data.phoneNumber) {
          //   const x = result.data.data.phoneNumber
          //     .replace(/\D/g, "")
          //     .match(/(\d{4})(\d{3})(\d{3})/);
          //   this.phoneNumber = `${x[1]} ${x[2]} ${x[3]}`;
          // }
          this.phoneNumber = result.data.data.phoneNumber;

          this.link = result.data.data.link;
          this.companyDescription = result.data.data.companyDescription;
          this.requestTypes = result.data.data.requestTypes;
          if (result.data.data.companyLogo)
            this.image = `${this.serverUrl}uploads/${result.data.data.companyLogo}`;
          if (
            result.data.data.assignedStaffs &&
            result.data.data.assignedStaffs.length > 0
          )
            this.staffId = result.data.data.assignedStaffs.map(item => {
              return item.staffId;
            });

          if ("bank" in result.data.data) {
            this.bank.accountName = result.data.data.bank.accountName;
            this.bank.accountNumber = result.data.data.bank.accountNumber;
            this.bank.bsb = result.data.data.bank.bsb;
          }
        })
        .catch(err => {});
    },
    async getStaff(partnerId) {
      await this.fetchStaffByPartnerId(partnerId)
        .then(result => {
          this.staffs = result.data.data;
        })
        .catch(err => {});
    },
    async savePage() {
      this.$vs.loading();
      this.$validator.validateAll().then(async result => {
        if (result) {
          let staff =
            this.staffId.length > 0
              ? this.staffId.map(item => {
                  return { staffId: item };
                })
              : [];
          let data = new FormData();
          data.append("title", this.title);
          data.append("companyName", this.companyName);
          data.append("companyHeader", this.companyHeader);
          data.append("companyDescription", this.companyDescription);
          data.append("phoneNumber", this.phoneNumber);
          data.append("companyEmail", this.companyEmail);
          data.append("link", this.link);
          data.append("abn", this.abn);
          data.append("address", this.address);
          data.append("assignedStaffs", JSON.stringify(staff));
          data.append("requestTypes", JSON.stringify(this.requestTypes));
          data.append("companyLogo", this.companyLogo);
          data.append("bank", JSON.stringify(this.bank));

          let obj = {
            id: this.$route.params.id,
            data: data,
            config: {
              header: {
                "Content-Type": "application/json"
              }
            }
          };
          if (this.$route.params.id) this.updatePage(obj);
          else this.storePage(obj);
          this.$vs.loading.close();
        }
        this.$vs.loading.close();
      });
      this.$vs.loading.close();
    },
    async updatePage(obj) {
      this.$vs.loading();

      await this.updatePageById(obj)
        .then(result => {
          this.$vs.loading.close();
          this.showMessage(
            "Successful",
            "Page has been updated successfully.",
            "success",
            "icon-check-circle"
          );
          this.$router.push({ name: "partner-pages" });
        })
        .catch(err => {
          this.$vs.loading.close();
          this.showMessage(
            "Error",
            "Page could not be updated.",
            "danger",
            "icon-times"
          );
          
        });
    },
    async storePage(obj) {
      this.$vs.loading();

      await this.createPage(obj)
        .then(result => {
          this.$vs.loading.close();
          this.showMessage(
            "Successful",
            "Page has been created successfully.",
            "success",
            "icon-check-circle"
          );
          this.$router.push({ name: "partner-pages" });
        })
        .catch(err => {
          this.$vs.loading.close();
          this.showMessage(
            "Error",
            "Page could not be created.",
            "danger",
            "icon-times"
          );
          
        });
    },
    showMessage(
      title = "Successful",
      message = "Task completed",
      color = "success",
      icon = "icon-check-circle"
    ) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,

      });
    }
  },
  mounted() {
    if (this.$route.params.id) this.getPageById(this.$route.params.id);
    this.getStaff(this.partnerId);
    document
      .getElementById("custom-textarea")
      .addEventListener("focusin", e => {
        document.getElementById("custom-focus").classList.add("hasValue");
      });
    document
      .getElementById("custom-textarea")
      .addEventListener("focusout", e => {
        if (this.companyDescription === "") {
          document.getElementById("custom-focus").classList.remove("hasValue");
        }
      });
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    }
  }
};
</script>